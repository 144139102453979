.ai-container-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1080px;
  margin: 0px auto;
  width: 100%;
  margin-top: 120px;
  // padding: 40px;

  .ai-container-header {
    margin: 20px 0px;
    h1 {
      background: linear-gradient(116.56deg, #2665ff, #36b1f2);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      margin-top: 20px;
      font-size: 38px;
      font-weight: bolder;
      line-height: 1.3;
    }
  }

  .ai-container-subHeader {
    margin-bottom: 20px;
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .ai-container-feature {
    display: flex;
    flex-direction: row;
    // margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    .list-item {
      display: flex;
      line-height: 20px;
      min-width: 278px;
      max-width: 300px;

      .list-item-text {
        margin: 0 10px;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 48px;
        color: #475467;
      }
    }
  }

  .ai-container-button {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .ai-container-landing {
    height: auto;
    justify-content: start;
    margin: 15px;
    margin-top: 120px;
    padding: 0 40px;


    .ai-container-header {
      h1 {
        margin-top: 15px;
        font-size: 38px;
      }
    }

    .ai-container-feature {
      flex-direction: row;
      justify-content: space-between;
      margin: 15px;
      .list-item-text {
        font-weight: 200;
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .ai-container-landing {
    height: auto;
    text-align: start;
    align-items: flex-start;
    margin: 20px 10px;
    height: auto;
    padding: 0 40px;
    margin-top: 70px;
    .ai-container-header {
      h1 {
        font-size: 32px;
        font-weight: bolder;
      }
    }

    .ai-container-feature {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .list-item {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0;
      }
    }
  }
}
