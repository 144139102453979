.card-container {
  width: 456px;
  height: 100%;
  min-height: 147px;
  min-width: 313px;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(39, 104, 255, 0.2);
  border-radius: 20px;
  // display: flex;
  position: relative;
  margin-bottom: 20px;
  padding: 20px;

  // .card {
  &:hover {
    .rectangle {
      background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);
    }
  }
  .rectangle {
    width: 14px;
    height: 56px;
    position: absolute;
    top: 22px;
    left: 0px;
    background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
    border-radius: 0px 20px 20px 0px;
  }

  .card-heading {
    position: relative;
    left: 15px;
    font-size: 22px;
    font-weight: 600;
  }

  .card-text {
    position: relative;
    left: 15px;
    font-weight: 400;
    font-size: 18px;
    width: 90%;
    margin-top: 15px;
  }
  // }
}

@media (max-width: 500px) {
  .card-container {
    width: 90%;
    height: auto;
    min-height: auto;
    margin: 0 auto;
    .rectangle {
      position: absolute;
      width: 9.68px;
      height: 38.73px;
      left: 0px;
      top: 15px;
    }
    .card-heading {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      padding: 0px;
    }
    .card-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
