.reviews-landing-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin:0;
  height: max-content;
  margin-bottom: 20px;
  // max-width: 1080px;

  // background: rgba(255, 255, 255, 0.1);
  // background-color: #f3f3f3;
  // backdrop-filter: blur(20px);
  // border-radius: 20px;
  padding: 0 20px;
  // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  // &:hover {
  //   transform: scale(1.02);
  //   box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  // }
  &.reviews-landing-wrapper-box {
    margin-top: 130px;
  }
  .reviews-landing-content {
  max-width: 1200px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    text-align: start;
    h2 {
      font-size: 3rem;
      line-height: 3.125rem;
      font-weight: bolder;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .reviews-landing-image {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    

    .review-image {
      // object-fit: contain;
      // width: 100%;
      // // max-width: 500px;
      // height: auto;
      // border-radius: 16px;


      object-fit: cover; /* Ensures proper scaling without stretching */
      width: 100%;
      max-width: 500px;
      height: auto;
      border-radius: 16px; /* Smooth rounded corners */
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
      transition: all 0.4s ease-in-out; /* Smooth transition effect */
      filter: brightness(95%) contrast(105%) saturate(110%); 
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .reviews-landing-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .reviews-landing-content {
      text-align: center;
      align-items: center;
      gap: 30px;

      h2 {
        font-size: 2.25rem;
        line-height: 2.75rem;
      }

      p {
        font-size: 15px;
        line-height: 1.75rem;
        max-width: 90%;
      }
    }

    .reviews-landing-image {
      padding-top: 20px;

      .review-image {
        width: 90%;
        max-width: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .reviews-landing-wrapper {
    flex-direction: column;
    // margin: 15px;
    padding: 20px;
    min-height: 80vh;
    &.reviews-landing-wrapper-box {
      margin-top: 70px;
    }
    .reviews-landing-content {
      text-align: start;

      h2 {
        font-size: 24px;
        line-height: 2.5rem;
      }

      p {
        font-size: 18px;
        line-height: 1.5rem;
      }
    }

    .reviews-landing-image {
      display: flex;
      justify-content: center;
      padding-top: 15px;

      .review-image {
        width: 100%;
        max-width: auto;
        height: auto;
      }
    }
  }
}
