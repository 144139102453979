.container {
  margin: 150px auto 131px auto;
  .bg-mobile {
    display: none;
  }
  .heading-box {
    margin-bottom: 120px;
    .heading {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;

      .our {
        font-weight: 300;
        font-size: 50px;
      }

      .associations {
        font-weight: 600;
        font-size: 50px;

        font-style: normal;
        text-align: center;
        color: #000000;
      }
    }
  }
  .text-div {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    .text-1 {
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
      line-height: 30px;
    }
    .so-much-more {
      display: none;
    }
  }

  .box-1 {
    max-width: 100%;
    display: flex;
    z-index: -1;
    padding: 0px 0px 50px 0px;
  }

  .see-all-button {
    display: none;
  }
}
@media (max-width: 480px) {
  .container {
    margin: 0px 0px 0px 0px;

    .bg-mobile {
      display: block;
      position: absolute;
      right: 0;
      z-index: -1;
    }
    .heading-box {
      margin-bottom: 60px;
      .heading {
        .our {
          font-size: 28px;
          margin-top: 60px;
        }
        .associations {
          font-size: 28px;
        }
      }
    }

    .text-div {
      .text-1 {
        font-size: 18px;
        padding: 0 20px;
      }
      .so-much-more {
        display: block;
      }
    }

    .see-all-button {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 40px 0px;
    }
  }
}

// @media (min-width: 381px) and (max-width: 1024px) {
//   .container {
//     margin: 152px auto 65px auto;
//   }
// }
