.feature-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px;
    
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 120px;
    // background: linear-gradient(to bottom, rgba(0, 123, 255, 0.1), rgba(135, 206, 235, 0.3));
    // border-radius: 16px;
    // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

    // backdrop-filter: blur(10px);

    .feature-title {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #1a202c;
    }
    .feature-sub-title {
        font-size: 18px;
        text-align: center;
        line-height: 26px;
        color: #4a5568;
    }
    .feature-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;
        width: 100%;

        .feature-card {
            background: rgba(255, 255, 255, 0.2);
            // background-color: white;
            border-radius: 16px;
            padding: 20px;
            text-align: center;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;
            // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);


            box-shadow: 4px 4px 10px rgba(221, 87, 255, 0.3), -4px -4px 10px rgba(61, 88, 230, 0.3);
            transition: box-shadow 0.3s ease, transform 0.3s ease;
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
                // background: rgba(255, 255, 255, 0.3);
            }

            .feature-icon {
                height: 60px;
                width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.4);
                border-radius: 50%;
                // margin: 0 auto 15px;
                backdrop-filter: blur(5px);
            }

            .feature-card-title {
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 10px;
                color: #2d3748;
                text-align: start;
            }

            .feature-card-sub-title {
                color: #718096;
                font-weight: 400;
                line-height: 22px;
                text-align: start;
            }
        }
    }
}

@media (max-width: 768px) {
    .feature-list-wrapper {
        .feature-title {
            font-size: 28px;
        }
        .feature-sub-title {
            font-size: 16px;
        }
        .feature-list {
            grid-template-columns:repeat(auto-fit, minmax(200px, 1fr));
        }
    }
}
