.box-container {
  min-width: 247px;
  height: 201px;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(39, 104, 255, 0.2);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 34px;
} 

@media (max-width: 480px) {
 
}
