.offer-container {
  // margin: auto;
  position: relative;
  margin: 50px auto 150px auto;
  .offer-background {
    position: absolute;
    height: 960px;
    width: 100%;
  }
  .heading-box {
    position: relative;
    top: 89px;
    text-align: center;

    .offer-heading {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;

      .what {
        font-weight: 300;
        font-size: 50px;
        text-align: center;
      }

      .we-offer {
        font-weight: 600;
        font-size: 50px;
      }

      .text {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  .rectangle {
    position: absolute;
    top: 112px;
    right: 0px;
    width: 378px;
    height: 320px;
  }

  .img-cards-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 175px;
    max-width: 1080px;
    margin: 0 auto;

    .cards {
      .card-image {
        display: none;
      }
    }

    .img {
      position: relative;
      right: 0;
      flex-shrink: 2;
      max-width: 534px;
      max-height: 503px;
    }
  }
}

@media (max-width: 1000px) {
  .offer-container {
    padding: 20px;
    margin-top: 50px;
    .heading-box {
      position: relative;
      top: 100px;
      text-align: center;

      .offer-heading {
        display: flex;
        justify-content: center;
        padding-bottom: 30px;

        .what {
          font-weight: 300;
          font-size: 30px;
          text-align: center;
        }

        .we-offer {
          font-weight: 600;
          font-size: 30px;
        }

        .text {
          font-weight: 400;
          font-size: 18px;
        }
      }
    }

    .img-cards-container {
      justify-content: center;
      top: 130px;

      .cards {
        .card-image {
          display: block;
          margin: 0 auto;
          margin-top: 20px;
        }
      }

      .img {
        display: none;
      }
    }
  }
}
