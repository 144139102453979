.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .header {
    // margin-top: 100px;
    text-align: center;
    max-width: 782px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text-background {
      padding: 4px 12px;
      text-align: center;
      min-width: 109px;
      background: #eaf3fe;
      mix-blend-mode: multiply;
      border-radius: 16px;
      .text {
        background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .heading {
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 70px;
      text-align: center;
      background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .supporting-text {
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 70px;
      text-align: center;
      background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 28px;
    }
  }
  .images-div {
    position: relative;
    margin-top: 63px;
    .tablet-screen {
    }
    .iphone-mockup {
      position: absolute;
      top: 86px;
      left: -96px;
    }
  }
  .ai-landing {
    display: flex;
    text-align: center;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
  }
  .stack-div {
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .stack {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.2s ease-in-out;
      cursor: auto;
      border-radius: 10px;

      &:hover {
        transform: translateY(-5px);
        box-shadow: rgba(120, 120, 218, 0.683) 0px 0px 16px;
        background-color: #ffffff;
      }
    }

    .link {
      margin: 20px 64px 0px 0px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

@media (max-width: 1000px) {
  .container {
    .images-div {
      img {
        width: 100%;
        height: 100%;
      }
      .iphone-mockup {
        display: none;
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    .header {
      .heading {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .supporting-text {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 70px;
        text-align: center;
        background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 28px;
      }
    }
  }
}
