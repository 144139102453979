.container {
  margin: auto;

  .container-wrapper {
    margin: 0 auto;
    margin-bottom: 0;

    .header {
      max-width: 1080px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .left-header-content {
        .top {
          border: 2px solid black;
          width: 200px;
          border-radius: 20px;
          padding: 5px;

          span {
            border: 2px solid red;
            border-radius: 20px;
            padding-left: 3px;
            margin-right: 6px;
          }
        }
      }

      .left-header-content h1 {
        margin-bottom: 20px;
        font-size: 3.2rem;
        margin-top: 15px;
      }
    }

    .section-one {
      margin: 40px 0 0 0;
      padding: 50px 0 0 0;

      .header-wrapper {
        background-color: #f9fafb;
        width: 100%;
        padding: 10px 0;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        .section-one-header {
          max-width: 800px;
          text-align: center;

          h2 {
            margin-bottom: 20px;
            font-size: 2rem;
          }
        }
      }

      .section-one-content-wraper {
        max-width: 1080px;
        margin: 40px auto;
        margin-bottom: 0;

        .heading {
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h2 {
            margin: 20px 0;
            font-size: 2rem;
            text-align: center;
          }

          p {
            line-height: 28px;
            text-align: center;

            span {
              font-weight: bold;
            }
          }
        }

        .feature-list-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 40px 0;
          max-width: 1080px;
          margin: 0 auto;

          .feature-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;

            .feature-card {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 15px;
              transition: all 0.2s ease-in-out;
              cursor: auto;
              border-radius: 10px;
              .feature-icon {
                border-radius: 10px;
                background: var(--Base-White, #fff);
                height: 48px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              }

              .feature-card-title {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                margin: 20px 0px 8px 0px;
                line-height: 24px;
              }

              .feature-card-sub-title {
                color: #475467;
                font-weight: 400;
                line-height: 24px;
              }

              &:hover {
                transform: translateY(-5px);
                box-shadow: rgba(120, 120, 218, 0.362) 0px 0px 16px;
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }

    .section-two {
      max-width: 1080px;
      margin: 0 auto;
      padding: 50px 0 0 0;
      // padding: 15px;
      .section-two-wrapper {
        .section-two-header {
          text-align: center;

          h2 {
            margin: 20px;
            font-size: 2rem;
          }

          p {
            font-size: 1.1rem;
          }
        }

        .section-two-content {
          margin-top: 50px;
          padding-left: 140px;

          .list {
            margin-top: 40px;
            padding-top: 10px;
            line-height: 4rem;

            h3 {
              font-size: 1.6rem;
            }

            ul > li {
              list-style: none;
            }
          }

          p {
            font-weight: bold;
          }
        }
      }
    }

    .generavit-ai {
      max-width: 1080px;
      margin: 180px auto 50px auto;
      display: flex;
      align-items: center;
      gap: 70px;

      .generavit-ai-header-left {
        display: flex;
        flex-direction: column;

        .generavit-ai-button {
          width: 100px;
          margin: 19px 0;
          padding: 10px;
          border-radius: 10px;
          font-size: 15px;
          background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
          border: none;
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .generavit-ai-header-left h1 {
        font-size: 3rem;
        margin-bottom: 30px;
      }

      .generavit-ai-header-left p {
        line-height: 28px;
        font-size: 1.2rem;
      }
    }
  }

  .technology-image {
    border-radius: 10px;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1000px) {
  .container {
    margin: auto;
    padding: 15px;
    .container-wrapper {
      margin: 0px auto;
      margin-bottom: 0;

      .generavit-ai {
        flex-direction: column;
        margin: 130px auto 0px auto;
        width: 100%;
        gap: 30px;

        .generavit-ai-header-left h1 {
          font-size: 24px;
          margin-bottom: 30px;
        }

        .generavit-ai-header-left p {
          line-height: 24px;
          font-size: 18px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .left-header-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h1 {
            margin-bottom: 20px;
            font-size: 24px;
            margin-top: 15px;
          }
          p {
            font-size: 16px;
            text-align: center;
            line-height: 22px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .section-one {
        margin: 50px 0 0 0;
        padding: 50px 0 0 0;

        .header-wrapper {
          background-color: #f9fafb;
          width: 100%;
          padding: 10px 0;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          .section-one-header {
            max-width: 800px;
            text-align: center;

            h2 {
              margin-bottom: 20px;
              font-size: 24px;
              line-height: 30px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .section-one-content-wraper {
          max-width: 1080px;
          margin: 50px auto;
          margin-bottom: 0;

          .heading {
            padding: 15px;

            h2 {
              margin: 20px 0;
              font-size: 24px;
            }

            p {
              line-height: 24px;

              span {
                font-weight: bold;
              }
            }
          }

          .feature-list-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 40px 0;
            max-width: 1080px;
            margin: 0 auto;

            .feature-list {
              display: grid;
              grid-template-columns: 1fr;
              gap: 20px;

              .feature-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 15px;
                transition: all 0.2s ease-in-out;
                cursor: auto;
                border-radius: 10px;
                .feature-icon {
                  border-radius: 10px;
                  background: var(--Base-White, #fff);
                  height: 48px;
                  width: 48px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }

                .feature-card-title {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  margin: 20px 0px 8px 0px;
                  line-height: 24px;
                }

                .feature-card-sub-title {
                  color: #475467;
                  font-weight: 400;
                  line-height: 24px;
                }

                &:hover {
                  transform: translateY(-5px);
                  box-shadow: rgba(120, 120, 218, 0.362) 0px 0px 16px;
                  background-color: #ffffff;
                }
              }
            }
          }
        }
      }

      .section-two {
        max-width: 1080px;
        margin: 0 auto;
        padding: 50px 0 0 0;
        // padding: 15px;
        .section-two-wrapper {
          .section-two-header {
            text-align: center;

            h2 {
              margin: 20px;
              font-size: 2rem;
            }

            p {
              font-size: 1.1rem;
            }
          }

          .section-two-content {
            margin-top: 50px;
            padding-left: 140px;

            .list {
              margin-top: 40px;
              padding-top: 10px;
              line-height: 4rem;

              h3 {
                font-size: 1.6rem;
              }

              ul > li {
                list-style: none;
              }
            }

            p {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
