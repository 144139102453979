.container {
  
  margin: 180px auto 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .bg-img {
    position: absolute;
    top: -580px;
    height: 1746px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 29px;
      text-align: center;
      letter-spacing: -0.398997px;
      color: #06243e;
    }

    .supporting-text {
      
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.398997px;
      color: #06243e;
      text-align: center;
      margin: 40px 0px 52px 0px;
    }
  }

  .images {
    display: flex;
    position: relative;
    justify-content: space-between;
    max-width: 753px;
    width: 100%;

    .box-1 {
      // margin-right: 37%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .rectangle {
        position: relative;
        top: -197px;
        left: -5px;
        width: 203.83px;
        height: 181.18px;
        background: #ffffff;
        box-shadow: 0px 20px 40px rgba(39, 104, 255, 0.2);
        border-radius: 20px;
        .name-img {
          position: relative;
          left: 38px;
          top: 37px;
          width: 126.54px;
          height: 107.02px;
        }
      }

      .text {
        
        font-style: normal;
        font-weight: 500;
        font-size: 20.1887px;
        line-height: 26px;
        color: #2b2934;
        position: relative;
        top: -140px;
        // margin-top: 54px;
      }
    }

    .box-2 {
      // margin-right: 137px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .background-img {
        position: relative;
        top: -25px;
      }
      .rectangle {
        position: relative;
        top: -234px;
        right: -10px;
        width: 203.83px;
        height: 181.18px;
        background: #ffffff;
        box-shadow: 0px 20px 40px rgba(39, 104, 255, 0.2);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        
        font-style: normal;
        font-weight: 500;
        font-size: 20.1887px;
        line-height: 26px;
        color: #2b2934;
        position: relative;
        top: -179px;
        // margin-top: 18px;
      }
    }
  }

  .bg-img-mobile {
    display: none;
  }

  .text-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      
      font-style: normal;
      font-weight: 500;
      font-size: 42px;
      line-height: 180%;
      text-align: center;
      color: #2b2934;
    }
    .text-mobile {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .container {
    margin: 150px 0px 70px 0px;
    padding: 10px;

    .header {
      .heading {
        font-size: 24px;
      }
      .supporting-text {
        font-size: 16px;
      }
    }

    .bg-img {
      display: none;
    }

    .images {
      flex-direction: column;

      .box-1 {
        margin: 0px auto 0px auto;
        .rectangle {
          top: -200px;
          left: -8px;
        }
        .text {
          margin: 0px;
        }
      }
      .box-2 {
        margin: 0px;
        .rectangle {
          top: initial;
          right: -8px;
          bottom: 228px;

          .name-img {
            width: 126.54px;
            height: 107.02px;
          }
        }
        .background-img {
          // top:0;
          // right: 0;
        }
      }
    }

    .bg-img-mobile {
      display: block;
      position: absolute;
      right: 0;
      top: 432px;
      z-index: -1;
    }

    .text-button-div {
      margin:0px;
      .text {
        display: none;
      }
      .text-mobile {
        display: block;
        
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 125%;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
        .bold-text {
          font-weight: 600;
        }
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .container {
    .bg-img {
      display: none;
    }
    .images {
      display: block;
      .box-1 {
        margin-bottom: 40px;
        .rectangle {
          top: -205px;
          left: -7px;
        }
      }
      .box-2 {
        .rectangle {
          top: initial;
          bottom: 227px;
          right: -6px;
        }
      }
    }
    .text-button-div{
      margin: 0px;
    }
  }
}
