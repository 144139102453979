.customer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  gap: 40px;
  padding:0 20px;
  .customer-title {
    color: #8599ac;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 0.03em;
    line-height: 1.38;

    p {
      margin-bottom: 20px;
    }
  }

  .customer-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 20px;
    .customer-logo {
      object-fit: contain;
      margin: auto;
      height: 60px;
      width: 60px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .customer-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .customer-list {
    grid-template-columns: repeat(2, 1fr);
    .customer-logo {
      height: 30px;
      max-width: 30px;
    }
  }
}
