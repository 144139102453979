.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .dot-img {
    position: absolute;
    top: 0;
    right: 0;
  }
  .left-img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .left-img-mobile {
    display: none;
  }
  .header-container {
    margin-bottom: 30px;
    max-width: 1210px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .heading-text {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.398997px;
      color: #2b2934;

      .bold-text {
        font-weight: 600;
      }
    }

    .supporting-text {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }

  .animation-container {
    margin-top: 100px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .animation-wrapper {
      height: 615px;
      width: 639px;
      position: absolute;
      top: 0;

      .background-div {
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 102px;
        width: 102px;
        position: absolute;
      }

      .heading {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 50px;
        color: #2b2934;
      }
      .supporting-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: #2b2934;
        opacity: 0.5;
      }

      .logo-div {
        .background-div-1 {
          @extend .background-div;
          box-shadow: 0px 20px 40px rgba(255, 147, 65, 0.2);
          top: -25px;
          left: 262px;
        }
        .text {
          position: absolute;
          right: 200px;
          top: -110px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
        .icon {
          width: 40px;
          height: 40px;
        }
      }

      .ignite-div {
        .background-div-2 {
          @extend .background-div;
          box-shadow: 0px 20px 40px rgba(30, 213, 182, 0.2);
          position: absolute;
          right: 64px;
          top: 100px;
        }
        .text {
          position: absolute;
          right: -200px;
          top: 100px;
          max-width: 250px;
        }
        .icon {
          width: 40px;
          height: 35.5px;
        }
      }

      .build-product-div {
        .background-div-3 {
          @extend .background-div;
          box-shadow: 0px 20px 40px rgba(39, 104, 255, 0.2);
          right: 64px;
          top: 313px;
        }

        .text {
          position: absolute;
          right: -200px;
          bottom: 150px;
          max-width: 250px;
        }
        .icon {
          width: 38px;
          height: 40px;
        }
      }

      .build-nurture-div {
        .background-div-4 {
          @extend .background-div;
          box-shadow: 0px 20px 40px rgba(164, 47, 255, 0.2);
          bottom: 100px;
          left: 262px;
        }

        .text {
          position: absolute;
          right: 200px;
          bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .icon {
          width: 40px;
          height: 28.19px;
        }
      }

      .direction-div {
        .background-div-5 {
          @extend .background-div;
          box-shadow: 0px 20px 40px rgba(30, 213, 182, 0.2);
          left: 64px;
          top: 315px;
        }
        .text {
          position: absolute;
          bottom: 207px;
          left: -190px;
          max-width: 250px;
        }
        .icon {
          width: 40px;
          height: 35.47px;
        }
      }

      .help-to-rise-div {
        .background-div-6 {
          @extend .background-div;
          box-shadow: 0px 20px 40px rgba(164, 47, 255, 0.2);
          left: 64px;
          top: 100px;
        }
        .text {
          position: absolute;
          left: -200px;
          top: 101px;
          text-align: right;
          max-width: 250px;
        }
        .icon {
          width: 36px;
          height: 40px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    margin-top: 50px;
    .dot-img {
      display: none;
    }
    .left-img {
      display: none;
    }
    .left-img-mobile {
      position: absolute;
      left: 0;
      top: -48px;
    }

    .header-container {
      padding: 0px 21px;
      margin: 0px;
      .heading-text {
        font-size: 24px;
      }
      .supporting-text {
        font-size: 16px;
      }
    }

    .animation-container {
      max-width: 480px;
      min-width: 375px;
      img {
        width: 55%;
      }
      .video {
        width: 226px;
        height: 226px;
      }
      .animation-wrapper {
        .background-div {
          width: 34.05px;
          height: 34.05px;
        }
        .heading {
          font-size: 16px;
          line-height: 110%;
        }
        .supporting-text {
          font-size: 14px;
          line-height: 144%;
        }

        .logo-div {
          .background-div-1 {
            top: 0px;
            left: 300px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 91px !important;
            text-align: center;
            right: 276px;
            top: -73px;
          }
        }

        .ignite-div {
          .background-div-2 {
            right: 216px;
            top: 51px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 68px !important;
            right: 157px;
            top: -40px;
          }
        }

        .build-product-div {
          .background-div-3 {
            right: 215px;
            top: 160px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 97px !important;
            right: 150px;
            bottom: 270px;
          }
        }

        .build-nurture-div {
          .background-div-4 {
            left: 300px;
            bottom: 365px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 93px !important;
            bottom: 265px;
            right: 270px;
            text-align: start;
            word-break: break-all;
          }
        }

        .direction-div {
          .background-div-5 {
            left: 205px;
            top: 150px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 97px !important;
            left: 155px;
            bottom: 350px;
            text-align: center;
          }
        }

        .help-to-rise-div {
          .background-div-6 {
            left: 215px;
            top: 45px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 97px !important;
            text-align: right;
            left: 140px;
            top: -59px;
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .container {
    margin-top: 50px;
    .left-img {
      display: none;
    }
    .header-container {
      padding: 0px 21px;
      margin: 0px;
      .heading-text {
        font-size: 35px;
      }
    }
    .animation-container {
      max-width: 480px;
      min-width: 375px;
      .video {
        width: 350px;
        height: 350px;
      }
      .animation-wrapper {
        height: 375px;
        width: 620px;
        .background-div {
          width: 46px;
          height: 46px;
        }
        .heading {
          font-size: 16px;
          line-height: 110%;
        }
        .supporting-text {
          font-size: 14px;
          line-height: 144%;
        }

        .logo-div {
          .background-div-1 {
            top: -2px;
            left: 290px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 91px !important;
            text-align: center;
            right: 260px;
            top: -78px;
          }
        }

        .ignite-div {
          .background-div-2 {
            right: 100px;
            top: 115px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 68px !important;
            right: 87px;
            top: -5px;
          }
        }

        .build-product-div {
          .background-div-3 {
            right: 100px;
            top: 335px;
            .icon {
              width: 25.03px;
              height: 25.03px;
            }
          }
          .text {
            max-width: 97px !important;
            right: 40px;
            bottom: -145px;
          }
        }

        .build-nurture-div {
          .background-div-4 {
            left: 287px;
            bottom: -117px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            // max-width: 93px !important;
            bottom: -162px;
            right: 211px;
          }
        }

        .direction-div {
          .background-div-5 {
            left: 95px;
            top: 335px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 97px !important;
            left: 75px;
            bottom: -85px;
            text-align: center;
          }
        }

        .help-to-rise-div {
          .background-div-6 {
            left: 95px;
            top: 100px;
            .icon {
              width: 19.03px;
              height: 19.03px;
            }
          }
          .text {
            max-width: 97px !important;
            text-align: right;
            left: 60px;
            top: -28px;
          }
        }
      }
    }
  }
}

