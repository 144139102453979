.technologies-wrapper {
  margin-top: 250px;
  .technologies-container {
    .technologies-header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 40px;

      .technologies-heading {
        text-align: center;
        font-size: 50px;
        font-weight: 600;
      }

      .technologies-subheading {
        max-width: 900px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
      }
    }

    .technologies-list {
      display: flex;
      max-width: 1000px;
      justify-content: center;
      margin: 0 auto;
      margin-top: 50px;
      flex-wrap: wrap;

      .technology {
        display: flex;
        flex-direction: column;
        // gap: 15px;

        .technology-image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 30px;
          border-radius: 4px;
          height: 130px;
          // width: 160px;
          .technology-image {
            width: 100%;
            object-fit: contain;
          }
        }

        .technology-name {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .technologies-list-by-category {
      display: flex;
      max-width: 1000px;
      justify-content: center;
      // align-items: center;
      margin: 70px auto 0px auto;
      width: 100%;
      gap: 30px;

      .technologies-list-by-category-left {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 50%;

        .technology-category {
          padding: 15px 20px;
          border-radius: 4px;
          box-shadow: rgba(219, 219, 249, 0.683) 0px 0px 16px;
          background-color: #ffffff;
          cursor: pointer;

          &.special {
            box-shadow: rgba(120, 120, 218, 0.683) 0px 0px 16px;
            background-color: #ffffff;
          }
        }
      }

      .technologies-list-category-right {
        width: 50%;
        height: 418px;
        padding: 5px 10px;
        border-radius: 4px;
        box-shadow: rgba(219, 219, 249, 0.683) 0px 0px 16px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .icons {
          display: flex;
          // padding: 28px 10px;
          width: 100%;
          // height: 120px;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px 9px;

          .technology-item {
            margin: 0 10px;
            position: relative;
            align-items: center;
            padding: 5px;
            border-radius: 5px;

            &:hover {
              transform: translateY(-5px);
              box-shadow: rgba(120, 120, 218, 0.683) 0px 0px 16px;
              background-color: #ffffff;
            }
          }

          .technology-item img {
            cursor: pointer;
          }

          .technology-item:hover::before {
            content: attr(data-tooltip);
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #333;
            color: #fff;
            padding: 8px;
            border-radius: 5px;
            font-size: 12px;
            opacity: 0.8;
            white-space: nowrap;
          }
        }
      }

      // .technologies-list-by-category-right {
      //     width: 50%;
      //     padding: 15px 20px;
      //     border-radius: 4px;
      //     box-shadow: rgba(219, 219, 249, 0.683) 0px 0px 16px;
      //     background-color: #ffffff;
      //     height: 535px;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     gap: 10px;

      //     .technologies-list-by-category-images {
      //       display: grid;
      //       gap: 15px;
      //       grid-template-columns: repeat(5, 1fr);

      //       img {
      //         object-fit: contain;
      //         width: 100%;
      //         border-radius: 4px;
      //       }
      //     }
      //   }
    }
  }
}
.openai-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;

  span {
    font-size: 10px;
    color: #fff;
    font-weight: 600;
  }
  .new {
    background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
    margin-top: 2px;
    padding: 2px 5px;
    font-size: 8px;
    border-radius: 4px;
    text-transform: uppercase;
  }
}
// background: linear-gradient(180deg, #e6f2fe 0%, #ffffff 100%);
.big-icons {
  --n: 16; /* number of item */
  --d: 120s; /* duration */

  width: 440px;
  height: 440px;
  margin: 0 auto;
  // border: 1px dashed rgba(38, 99, 255, 0.644);
  display: grid;
  grid-template-columns: 50px;
  grid-template-rows: 50px;
  place-content: center;
  border-radius: 50%;
  position: relative;
  // @include place-item-circle(300px, 300px, 8, 56);

  .big-icon {
    grid-area: 1/1;
    text-align: center;
    animation: bigspin var(--d) linear infinite;
    transform: rotate(0) translate(220px) rotate(0);

    &.icon-border {
      border: 2px solid rgba(38, 99, 255, 0.644);
      border-radius: 50%;
      overflow: hidden;
    }
  }

  @keyframes bigspin {
    100% {
      transform: rotate(1turn) translate(220px) rotate(-1turn);
    }
  }

  @for $i from 0 through 17 {
    .big-icon:nth-child(#{$i + 1}) {
      animation-delay: calc(-1 * $i * var(--d) / var(--n));
    }
  }
}

.med-icons {
  --n: 8; /* number of item */
  --d: 200s; /* duration */

  position: absolute; /* Important */
  top: 50%; /* Position Y halfway in */
  left: 50%; /* Position X halfway in */
  transform: translate(-50%, -50%); /* Move it halfway back(x,y) */
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border: 1px dashed rgba(38, 99, 255, 0.644);
  display: grid;
  grid-template-columns: 50px;
  grid-template-rows: 50px;
  place-content: center;
  border-radius: 50%;
  // @include place-item-circle(300px, 300px, 8, 56);

  .med-icon {
    grid-area: 1/1;
    text-align: center;
    animation: medicon var(--d) linear infinite;
    transform: rotate(0) translate(150px) rotate(0);
  }

  @keyframes medicon {
    100% {
      transform: rotate(1turn) translate(150px) rotate(-1turn);
    }
  }

  @for $i from 0 through 7 {
    .med-icon:nth-child(#{$i + 1}) {
      animation-delay: calc(-1 * $i * var(--d) / var(--n));
    }
  }
}

.sub-icons {
  --n: 5; /* number of item */
  --d: 120s; /* duration */

  position: absolute; /* Important */
  top: 50%; /* Position Y halfway in */
  left: 50%; /* Position X halfway in */
  transform: translate(-50%, -50%); /* Move it halfway back(x,y) */
  width: 160px;
  height: 160px;
  margin: 0 auto;
  border: 1px dashed rgba(38, 99, 255, 0.644);
  display: grid;
  grid-template-columns: 50px;
  grid-template-rows: 50px;
  place-content: center;
  border-radius: 50%;
  // @include place-item-circle(300px, 300px, 8, 56);

  .sub-icon {
    grid-area: 1/1;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    animation: subicons var(--d) linear infinite;
    transform: rotate(0) translate(80px) rotate(0);
  }

  @keyframes subicons {
    100% {
      transform: rotate(1turn) translate(80px) rotate(-1turn);
    }
  }

  @for $i from 0 through 7 {
    .sub-icon:nth-child(#{$i + 1}) {
      animation-delay: calc(-1 * $i * var(--d) / var(--n));
    }
  }
}

.icon-border {
  border: 2px solid rgba(38, 99, 255, 0.644);
  border-radius: 50%;
  overflow: hidden;
}
.react-native-bg {
  background-color: rgba(0, 216, 255, 0.16);
}

.nextjs-bg {
  background-color: rgba(178, 183, 184, 0.16);
}

.flutter-bg {
  background-color: rgba(0, 86, 158, 0.16);
}

.graphql-bg {
  background-color: rgba(225, 0, 152, 0.16);
}

.nodejs-bg {
  background-color: rgba(104, 159, 99, 0.16);
}

.postgre-bg {
  background-color: rgba(91, 131, 163, 0.16);
}

.devops-bg {
  background-color: rgb(215, 248, 229);
}

@media screen and (min-width: 551px) and (max-width: 950px) {
  .technologies-wrapper {
    .technologies-container {
      .technologies-list-by-category {
        .technologies-list-category-right {
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .technologies-wrapper {
    padding: 15px;
    .technologies-container {
      .technologies-header {
        .technologies-heading {
          font-size: 24px;
        }

        .technologies-subheading {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .technologies-container {
      .technologies-list-by-category {
        flex-wrap: wrap;

        .technologies-list-by-category-left {
          width: 90%;
        }

        .technologies-list-category-right {
          width: 90%;
          height: auto;

          .icons {
            gap: 0;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 359px) {
  .technologies-wrapper {
    margin-top: 100px;
  }
}
