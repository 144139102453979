.banner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  position: relative;
  background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);
  min-height: 750px;

  .bg-img {
    width: 100%;
    height: 100%;
    min-height: 500px;
    object-fit: cover;
  }

  .heading-box {
    position: absolute;
    top: 160px;

    .banner-heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;

      .words {
        overflow: hidden;
        height: 70px;
        .sp {
          display: flex;
          height: 100%;
          padding-left: 10px;
          // color: #0e6ffc;
          justify-content: center;
          animation: spin_words 7s infinite;

          @keyframes spin_words {
            10% {
              transform: translateY(-1%);
            }
            // 25%{
            //     transform: translateY(-90%);
            // }
            35% {
              transform: translateY(-100%);
            }
            50% {
              transform: translateY(-190%);
            }
            70% {
              transform: translateY(-270%);
            }

            100% {
              transform: translateY(-300%);
            }
          }

          .we-enable {
            font-weight: 300;
            font-size: 50px;
          }

          .entrepreneurs {
            font-weight: 600;
            font-size: 50px;
            color: #2665ff;
            display: block;
          }
        }
      }

      .banner-text-div {
        font-size: 18px;
        line-height: 28px;
        margin-top: 20px;
        max-width: 600px;
        text-align: center;
      }

      .stack-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;
        max-width: 700px;
        justify-content: center;
        .stack-tag {
          background: #5b5b5b1a;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 999px;
          padding: 6px 20px;
          width: max-content;
        }
      }

      .price-engine-button {
        max-width: 180px;
        margin: 30px auto 0;
        background: linear-gradient(90deg, #15d3b9, #0e6ffc, #a129ff);
        border: none;
        border-radius: 50px;
        padding: 10px 24px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        transition: transform 0.2s;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        animation:
          gradientAnimation 5s linear infinite,
          pulse 2s ease-in-out infinite,
          float 3s ease-in-out infinite;

        text-align: center;
        &:hover {
          transform: translateY(-2px);
        }

        &:active {
          transform: translateY(0);
        }
      }

      @keyframes gradientAnimation {
        0% {
          background-position: 0% 50%;
        }
        100% {
          background-position: 100% 50%;
        }
      }

      @keyframes float {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-10px);
        }
        100% {
          transform: translateY(0);
        }
      }
    }
  }

  .rocket-div {
    position: absolute;
    bottom: -40px;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .white {
    width: 100%;
    height: 30px;
    background-color: #fff;
  }
}

@media (max-width: 480px) {
  .banner-wrapper {
    // min-height: 650px;
    padding: 40px;
    .heading-box {
      top: 160px;

      .banner-heading {
        display: block;
        margin: 10px 20px;

        .words {
          .sp {
            display: block;
            padding: 0px;
            .we-enable {
              font-size: 28px;
              font-weight: 400;
              text-align: start;
            }
            .entrepreneurs {
              font-size: 28px;
              font-weight: 600;
              text-align: start;
            }
          }
        }
        .banner-text-div {
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
      }
    }
    .white {
      height: 10px;
    }
    .rocket-div {
      bottom: -10px;
      img{
        object-fit: contain;
      }
    }
  }
 
}

@media (min-width: 481px) and (max-width: 768px) {
  .banner-wrapper {
    padding: 50px;
    .heading-box {
      .banner-heading {
        .stack-list {
          // justify-content: flex-start;
        }
        .words {
          .sp {
            .we-enable {
              font-size: 35px;
            }
            .entrepreneurs {
              font-size: 35px;
            }
          }
        }
      }
    }
  }
}
