@import "../../../styles/mixin.scss";

.connectors-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);

    .connectors {
        // background: linear-gradient(245deg, #093053 34.1%, #021526 100.02%);
        padding: 80px 20px;

        .connectors-head {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-bottom: 40px;
            h3 {
                font-weight: 400;
                font-size: 30px;
                color: #fff;
                margin-bottom: 20px;
            }

            .tabs {
                display: flex;
                gap: 10px;

                .tab {
                    cursor: pointer;
                }

                .active-tab {
                    border: 10px solid;
                    border-image-slice: 1;
                    border-width: 5px;
                    border-image-source: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    // transition: all 0.2s ease-in-out;
                    span {
                        background: -webkit-linear-gradient(#2665ff, #36b1f2);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 500;
                    }
                }

                span {
                    display: block;
                    padding: 5px;
                }
            }

            .omni-info {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                margin-bottom: 10px;
                span {
                    color: #fff;
                    display: block;
                    width: 155px;
                    font-size: 14px;
                }

                .text-right {
                    text-align: right;
                }

                .round-up {
                    display: block;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
                }
            }
        }

        .icons {
            --n: 8; /* number of item */
            --d: 120s; /* duration */

            &.data-source {
                --n: 6; /* number of item */
                --d: 56s;
            }

            &.cloud {
                --n: 6;
                --d: 56s;
            }

            &.gateway {
                --n: 5;
                --d: 56s;
            }

            &.shipment {
                --n: 5;
                --d: 56s;
            }

            width: 300px;
            height: 300px;
            margin: 0 auto;
            border: 1px dashed rgba(38, 99, 255, 0.644);
            display: grid;
            grid-template-columns: 50px;
            grid-template-rows: 50px;
            place-content: center;
            border-radius: 50%;
            position: relative;
            // @include place-item-circle(300px, 300px, 8, 56);

            .icon {
                grid-area: 1/1;
                text-align: center;
                animation: spin var(--d) linear infinite;
                transform: rotate(0) translate(150px) rotate(0);

                &.icon-border {
                    border: 2px solid rgba(38, 99, 255, 0.644);
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            @keyframes spin {
                100% {
                    transform: rotate(1turn) translate(150px) rotate(-1turn);
                }
            }

            @for $i from 0 through 7 {
                .icon:nth-child(#{$i + 1}) {
                    animation-delay: calc(-1 * $i * var(--d) / var(--n));
                }
            }
        }
    }

    .connectors-light {
        // background: linear-gradient(180deg, #e6f2fe 0%, #ffffff 100%);
        .big-icons {
            --n: 16; /* number of item */
            --d: 120s; /* duration */

            width: 440px;
            height: 440px;
            margin: 0 auto;
            border: 1px dashed rgba(38, 99, 255, 0.644);
            display: grid;
            grid-template-columns: 50px;
            grid-template-rows: 50px;
            place-content: center;
            border-radius: 50%;
            position: relative;
            // @include place-item-circle(300px, 300px, 8, 56);

            .big-icon {
                grid-area: 1/1;
                text-align: center;
                animation: bigspin var(--d) linear infinite;
                transform: rotate(0) translate(220px) rotate(0);

                &.icon-border {
                    border: 2px solid rgba(38, 99, 255, 0.644);
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            @keyframes bigspin {
                100% {
                    transform: rotate(1turn) translate(220px) rotate(-1turn);
                }
            }

            @for $i from 0 through 17 {
                .big-icon:nth-child(#{$i + 1}) {
                    animation-delay: calc(-1 * $i * var(--d) / var(--n));
                }
            }
        }

        .med-icons {
            --n: 8; /* number of item */
            --d: 200s; /* duration */

            position: absolute; /* Important */
            top: 50%; /* Position Y halfway in */
            left: 50%; /* Position X halfway in */
            transform: translate(-50%, -50%); /* Move it halfway back(x,y) */
            width: 300px;
            height: 300px;
            margin: 0 auto;
            border: 1px dashed rgba(38, 99, 255, 0.644);
            display: grid;
            grid-template-columns: 50px;
            grid-template-rows: 50px;
            place-content: center;
            border-radius: 50%;
            // @include place-item-circle(300px, 300px, 8, 56);

            .med-icon {
                grid-area: 1/1;
                text-align: center;
                animation: medicon var(--d) linear infinite;
                transform: rotate(0) translate(150px) rotate(0);
            }

            @keyframes medicon {
                100% {
                    transform: rotate(1turn) translate(150px) rotate(-1turn);
                }
            }

            @for $i from 0 through 7 {
                .med-icon:nth-child(#{$i + 1}) {
                    animation-delay: calc(-1 * $i * var(--d) / var(--n));
                }
            }
        }

        .sub-icons {
            --n: 5; /* number of item */
            --d: 120s; /* duration */

            position: absolute; /* Important */
            top: 50%; /* Position Y halfway in */
            left: 50%; /* Position X halfway in */
            transform: translate(-50%, -50%); /* Move it halfway back(x,y) */
            width: 160px;
            height: 160px;
            margin: 0 auto;
            border: 1px dashed rgba(38, 99, 255, 0.644);
            display: grid;
            grid-template-columns: 50px;
            grid-template-rows: 50px;
            place-content: center;
            border-radius: 50%;
            // @include place-item-circle(300px, 300px, 8, 56);

            .sub-icon {
                grid-area: 1/1;
                text-align: center;
                animation: subicons var(--d) linear infinite;
                transform: rotate(0) translate(80px) rotate(0);
            }

            @keyframes subicons {
                100% {
                    transform: rotate(1turn) translate(80px) rotate(-1turn);
                }
            }

            @for $i from 0 through 7 {
                .sub-icon:nth-child(#{$i + 1}) {
                    animation-delay: calc(-1 * $i * var(--d) / var(--n));
                }
            }
        }

        .icon-border {
            border: 2px solid rgba(38, 99, 255, 0.644);
            border-radius: 50%;
            overflow: hidden;
        }
    }
    // .connectors {
    //     background: linear-gradient(180deg, #e6f2fe 0%, #ffffff 100%);
    // }

    .socommerz-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
        border-radius: 4px;

        span {
            font-size: 12px;
            color: #fff;
            font-weight: 600;
        }
    }

    .openai-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #000;

        span {
            font-size: 10px;
            color: #fff;
            font-weight: 600;
        }
        .new{
            background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
            margin-top: 2px;
            padding: 2px 5px;
            font-size: 8px;
            border-radius: 4px;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 1000px) {
    .connectors-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        .connectors {
            padding: 80px 0;
        }
        .connectors-light {
            width: 100%;
            overflow: hidden;
            .connectors-head {
                .tabs {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
    }
}
